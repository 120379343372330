@import '../../../scss/common.scss';
@import '../../../scss/variables.scss';
@import '../../../scss/animation.scss';

.rcw-conversation-container {
  @include animation(0, 0.5s, slide-in);
  border-radius: 10px;
  box-shadow: 0px 2px 10px 1px $grey-3;
}

.rcw-slide-out {
  @include animation(0, 0.5s, slide-out);
}

.rcw-full-screen {
  .rcw-conversation-container {
    @include conversation-container-fs;
  }
}

@media screen and (max-width: 800px) {
  .rcw-conversation-container {
    @include conversation-container-fs;
  }
}
