.app-header {
  display: flex;
  justify-content: space-between;
  color: #CCCCCC;
}

.user-panel > .panelelement {
  margin: 6px;
}

.app-header .app-logo
{
  width: 64px;
  height: 64px;
  background-color: white;
  object-fit: contain;
  font-family: "object-fit: contain;";
  object-position: center;
  box-sizing: border-box;
  display: inline-block;
  margin-right: 5px;
}