.login-title
{
	display: inline-block;
	clear: both;
	overflow: hidden;
	font-size: 20px;
  font-weight: 700;

}

.login-background
{
  background: url(./welcome.png) no-repeat center;
  background-size:100%;
  background-blend-mode:lighten;
  width:100%;
  height:100%;
}

.login-form {
  border-radius: 4px;
  background: #fff;
  padding: 32px 32px 0;
  min-width: 300px;
  box-shadow: 0 0 50px 0 rgba(0,0,0,0.2);
}

.login-title > .title {
  text-align: left;
  margin-bottom: 24px;
  font-size: 20px;
  height:100%;
  display:inline-block;
  vertical-align:middle;
  box-sizing:border-box;
}

.login-title > .logo {
  display: inline-block;
  margin: 0 auto 12px;
  width:64px;
  height:64px;
  vertical-align :middle;
  object-fit:scale-down;
  object-position:center;
  font-family:"object-fit: scale-down;";
  text-align: left;
}
.login-form .login-reset
{
  float: right !important;
}
