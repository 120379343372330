.c-powered-by a
  {
    background : url(./sft_logo.png) no-repeat left center;
    background-size: contain;
    background-clip: border-box;
    margin-left:5px;
    padding-left: 24px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-weight : bold;
  }

  .c-powered-by
  {
  padding-bottom: 28px;
  margin-left: auto;
  margin-right:auto;
  text-align:center;
  font-size : 11px;
  }